import React, { Component } from 'react';

type LoginProps = {};

type LoginState = {};

export class Login extends Component<LoginProps, LoginState> {

    constructor(props: LoginProps){
        super(props);

        this.state = {};

        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleLoginClick() {
        alert('hi');
    }
    

    render() {
        return <div>
            <input type="text" placeholder="Username" /><br />
            <input type="password" placeholder="Password" /><br />
            <button onClick={this.handleLoginClick}>Login</button>
        </div>
    }
}